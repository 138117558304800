import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"
import Blockquote from "@components/blockquote"

import ActiveItemGallery from "@components/ActiveItemGallery"

import {
  RequestMoreInfo,
} from "@components/Forms"

const galleryItems = [
  {
    key: 1,
    title: "Taurus #1",
    images: ["/images/guitar002-full.jpg"],
  },
  {
    key: 2,
    title: "Fabric Top",
    images: ["/images/guitar002-guitar.jpg"],
  },
  {
    key: 3,
    title: "Side",
    images: ["/images/guitar002-side.jpg"],
  },
  {
    key: 4,
    title: "Neck Back",
    images: ["/images/guitar002-neck-back.jpg"],
  },
  {
    key: 5,
    title: "Body Back",
    images: ["/images/guitar002-body-back.jpg"],
  },
  {
    key: 6,
    title: "Headstock",
    images: ["/images/guitar002-headstock.jpg"],
  },
  {
    key: 7,
    title: "Headstock Signed",
    images: ["/images/guitar002-headstock-signed.jpg"],
  },
  {
    key: 8,
    title: "Fretboard Inlays",
    images: ["/images/guitar002-inlays.jpg"],
  },
  {
    key: 9,
    title: "Plaque Front",
    images: ["/images/guitar002-body-plaque-front.jpg"],
  },
  {
    key: 10,
    title: "Plaque Back",
    images: ["/images/guitar002-body-plaque-back.jpg"],
  },
  {
    key: 11,
    title: "Builder Randy King",
    images: ["/images/guitar002-randy-king.jpg"],
  },
  {
    key: 12,
    title: "Rough Cut",
    images: ["/images/guitar002-rough-cut.jpg"],
  },
  {
    key: 13,
    title: "Rough Cut With Neck",
    images: ["/images/guitar002-rough-with-neck.jpg"],
  },
  {
    key: 14,
    title: "Fabric",
    images: ["/images/guitar002-fabric.jpg"],
  },
  {
    key: 15,
    title: "Finishing",
    images: ["/images/guitar002-finishing.jpg"],
  },
]

const Guitar002 = () => {
  return (
    <PageLayout>
      <SEO
        title="Paisely Fabric Top T-Style Guitar | Taurus"
        ogDescription="This oversized offset T-style guitar was a milestone for King Electric Guitars. My first client build and the introduction of my Taurus body shape. Let's build your dream guitar."
      />
      <SingleColumnBlock
        textPadded
        text={
          <div className="content">
            <h1>Taurus Amber Paisely Fabric Top T-Style Guitar</h1>
            <p className="detail">
              <b>Guitar:</b> Taurus #1
            </p>
            <p className="detail">
              <b>Finish:</b> Amber Tinted Paisely Fabric Top with Roasted Flame Maple Neck
            </p>
            <p className="detail">
              <b>Details:</b> King Electric Guitars Taurus body, T-style bridge, and Bootstrap Palo Duro pickups
            </p>
          </div>
        }
      />
      <ActiveItemGallery
        items={galleryItems}
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>This Guitar's Story</h2>
            <p>
              This guitar was a milestone for me & for King Electric Guitars. It was my first custom guitar build for a client! It was one of the first of what would become my Taurus model. When I built <a href="/guitar001-prototype-one/">prototype #1</a> in my shop, I posted a few pics on <a href="https://www.instagram.com/kingelectricguitars/" target="_blank" rel="noreferrer">social media</a> and within days I received a request from Mr. Chris Nylund, a potential client IN FRANCE! After discussing with Mr. Nylund, we decided on the specs and I got to work. Not wanting to make a copy of another guitar, We went with the Taurus shape that I was designing at the time.
            </p>
            <p>
              Chris asked for a purple paisley with binding on the back and sides, similar to prototype #1, but with natural sides and there HAD to be an arm carve.I spent several days on research & tooling to make his request a reality. His request is now a standard option on all of my models! Chris and I had many conversations along the way to ensure that the end product was what he dreamed of. All during the process, We talked & I sent pictures,until the guitar was in the finishing phase.
            </p>
            <p>
              Once it was complete, I handed it off for it to make its way to France, but I made a request of my own. I asked that he opened the case while I was on a video call with him. The look on his face & the huge smile as he held his handmade custom guitar for the first time spoke volumes!
            </p>
            <p>
              Quote from Mr Nylund upon recieving his guitar:
            </p>
            <Blockquote>
              I absolutely love it & I haven't even plugged it in yet!
            </Blockquote>
            <h2>Specifications</h2>
            <ul>
              <li>Roasted Flame Maple Neck & Fretboard</li>
              <li>Tru-oil Neck Finish</li>
              <li>25.5-inch Scale</li>
              <li>12-inch Fretboard Radius</li>
              <li>Med Cryowire Frets</li>
              <li>Dual Action Truss Rod</li>
              <li>Bolt on Neck With Inserts & Stainless Machine Screws</li>
              <li>Wenge Fretboard Dots</li>
              <li>Bone Nut</li>
              <li>Hipshot Locking Open Gear Tuners</li>
              <li>1-Piece Honduran Mahogony Body</li>
              <li>Paisley Fabric Top & Back</li>
              <li>Top Binding With arm Carve</li>
              <li>Back Binding</li>
              <li>Amber Tinted Urethane Topcoat</li>
              <li>Joe Barden Bridge With Compensated Saddles</li>
              <li>Reversed Control Plate With Angled Switch</li>
              <li>Bootstrap Palo Duro Pickups</li>
              <li>CTS 250k Pots</li>
              <li>Switchcraft Jack</li>
              <li>4-Way Pickup Selector</li>
              <li>.047 Orange Drop Cap</li>
              <li>Vintage Cloth Wire</li>
            </ul>
            <h2>Price and Availability</h2>
            <p className="red">
              <strong>SOLD</strong>
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Want me to build you something like it? Let's talk!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default Guitar002